import styled from "styled-components";

const Container = styled.div`
    position: fixed;
    top: 70px;
    width: 100%;
    max-width: 500px;
    height: ${ props => props.form ? "calc(100% - 103px)" : "calc(100% - 140px)" };
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export {
    Container
}