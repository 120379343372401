import styled from 'styled-components';

const Container = styled.div`
    position: fixed;    
    top: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 70;
`

const Modal = styled.div`
    width: 80%;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
`

const Form = styled.div`
    transition: 1s ease all;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 320px;
    width: 80%;
    min-width: 270px;

    border-radius: 15px;
    // background-color: rgba(255, 255, 255, 0.3);
    padding: 20px 10px;

    // border: 1px solid gray;
`

export {
    Container,
    Modal,
    Form
}