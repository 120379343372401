import { useEffect, useRef } from "react";
import { useState } from "react";
import { Page,
    // Logo,
    Area,
    Text,
    ButtonArea,
    ToggleButtonArea,
    BigButtonArea,
    ToggleButton,
    Button,
    Overlay,
    ButtonLink,
    LoadContainer
} from "./styles";

const Home = () => {
    // const [loading, setLoading] = useState(false);
    // useEffect(() => {
    //     setTimeout(() => { setLoading(false) }, 4000)
    // }, [])

    return (
    <Page>
        <video style={{
            position: "absolute",
            top: "0",
            left: "0",
            display: "block",
            width: "100vw",
            height: "100vh",
            "OobjectFit": "cover",
            "objectFit": "cover",
        }} src="https://gandaya.b-cdn.net/bg_trim_intetnuot.mp4" autoPlay loop muted playsInline></video>
        <Overlay />
        <Area>
            <Text />
            <ButtonArea>
                <BigButtonArea>
                    <ButtonLink to="/events/intertnuot-2023">Garanta sua Inscrição</ButtonLink>
                    {/* <Button>
                    <a style={{width: "100%", height: "100%", textDecoration: "none", display: "flex", justifyContent: "center", alignItems: "center"}} href="https://wa.me/+5511995093002">
                        <span style={{color: "white"}}>Informações</span>
                    </a>
                    </Button> */}
                </BigButtonArea>
            </ButtonArea>
        </Area>
    </Page>
    )
}

export default Home;