import styled from 'styled-components';

const Container = styled.div`
    height: 100%;
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: space-evenly;

    overflow-y: scroll;
`

const Button = styled.div`
    margin: 10px 0;
    height: 50px;
    width: 80%;
    align-self: center;
    
    text-align: center;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 5px;
    font-weight: bold;
    color: white;
    background-color: #038465;
    text-decoration: none;

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    position: absolute;
    bottom: 0;
`

export {
    Container,
    Button
}