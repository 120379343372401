import styled from 'styled-components';

const FormContainer = styled.div`
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-bottom: 20px;
`

const Input = styled.input`
    width: 220px;
    height: 2em;
    border-radius: 5px;
    border: 2px solid rgba(0, 0, 0, 0.2);
    padding: 5px 10px;
    margin: 7px 0;

    font-family: IdealSans;

    background-color: rgba(255, 255, 255, 0.9)
`

const Submit = styled.div`
    width: 220px;
    height: 2em;
    border-radius: 5px;
    border: none;
    padding: 5px 10px;
    margin: 7px 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #038465;
    color: white;
`

export {
    FormContainer,
    Input,
    Submit
}