import { useState } from "react";
import { useEffect } from "react";
import Login from "../../../assets/components/login/Login";
import useStore from "../../../store/store";
import { Button, Container } from "./styles";

const EventForm = () => {
    const user = useStore(state => state.user);
    const [submission, setSubmission] = useState(false)

    useEffect(() => {
        const script = document.createElement("script");
    
        script.src = "https://tally.so/widgets/embed.js";
        script.async = true;
    
        document.body.appendChild(script);
    }, [])

    

    return (
        !user ? <Login /> :
        <Container>
            <iframe data-tally-src="https://tally.so/r/wa9pXv?transparentBackground=1" width="100%" height="100%" frameborder="0" marginheight="0" marginwidth="0" title="Intertnuot 2023"></iframe>
        </Container>
    )
}

export default EventForm