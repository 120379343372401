import { Link } from 'react-router-dom'
import styled from 'styled-components'
import logo from '../../assets/images/logo.png'


const Page = styled.div`
    height: 100vh;
    width: 100vw;

    display: flex;
    align-items: center;
    justify-content: center;

    color: white;
    font-weight: 700;
`

const Area = styled.div`
    height: 500px;
    width: 310px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    z-index: 2;
`

const Text = styled.div`
    background-image: url(${logo});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    height: 200px;
    width: 200px;
`

const ButtonArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    height: 65%;
    width: 85%;
`

const ToggleButtonArea = styled.div`
    height: 25%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
`

const BigButtonArea = styled.div`
    height: 50%;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`

const ToggleButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 45%;
    height: 60%;
    border: 3px solid white;
    border-radius: 100px;
    ${props => props.active && "background-color: white; color: black;"}

    transition: 0.5s ease all;
    &:active {
        transform: scale(1.02) rotate(1.5deg);
    }

    cursor: pointer;
`

const Button = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 40%;
    border: 3px solid white;
    border-radius: 10px;
    ${props => props.active && "background-color: white; color: black;"}

    transition: 0.1s ease all;
    &:active {
        transform: scale(1.1) rotate(3deg);
    }

    cursor: pointer;
`

const ButtonLink = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 40%;
    border: 3px solid white;
    border-radius: 10px;
    ${props => props.active && "background-color: white; color: black;"}

    transition: 0.1s ease all;
    &:active {
        transform: scale(1.1) rotate(3deg);
    }

    cursor: pointer;

    text-decoration: none; color: white;
`

const Overlay = styled.div`
    height: 100vh;
    width: 100vw;

    position: fixed;
    top: 0;
    left: 0;

    background-color: rgba(0, 0, 0, 0.5);
    z-index:1;
`

const LoadContainer = styled.div`
    position: fixed;

    top: 0; 
    right: 0;
    height: 100vh;
    width: 100vw;

    background-color: rgba(255, 255, 255, 0.99);

    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 20;
`

export {
    Page,
    // Logo,
    Area,
    Text,
    ButtonArea,
    ToggleButtonArea,
    BigButtonArea,
    ToggleButton,
    Button, ButtonLink,
    Overlay,
    LoadContainer
}